<template>
  <div>
    <v-autocomplete
      :label="label"
      :items="dadosTabela"
      item-value="valor"
      item-text="valor"
      v-model="dadoAtualizado"
      dense
      no-data-text="Nenhum dado encontrado"
    />
  </div>
</template>

<script>
export default {
  name: 'InputDicionarioDadosAneel',
  props: {
    label: {
      type: String,
      default: null
    },
    entidadeBdgd: {
      type: String,
      required: true
    },
    campo: {
      type: String,
      required: true
    },
    dadoInicial: {
      required: true,
      default: null
    }
  },
  data() {
    return {
      dadoAtualizado: null
    };
  },
  mounted() {
    this.dadoAtualizado = String(this.dadoInicial).trim();
  },
  computed: {
    tabela() {
      return this.$store.getters.getTabelaPorCampo(
        this.entidadeBdgd,
        this.campo
      );
    },
    dadosTabela() {
      return this.$store.getters.getDadosTabela(this.tabela);
    }
  },
  watch: {
    dadoAtualizado() {
      this.$emit('dadoAlterado', this.dadoAtualizado);
    }
  }
};
</script>
